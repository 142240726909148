import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";

import {SiteSection} from "./SiteSection.js";

//
// SealingAreas extends SiteSection
//

export const SealingAreas = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.SealingAreas = SealingAreas;

SealingAreas.prototype = Object.create (SiteSection.prototype);

SealingAreas.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	element.addEventListener ("mouseover", this.mouseOver.bind (this));
	element.addEventListener ("mouseout", this.mouseOut.bind (this));
	
	const itemContainer = element.querySelector (".sealing-areas-states");
	
	const items = this.items = itemContainer.querySelectorAll (".sealing-areas-state-item");
	items.forEach (item => {
		item.addEventListener ("mouseover", this.mouseOverItem.bind (this));
		item.addEventListener ("click", this.clickItem.bind (this));
		
	});
	
	const illustrationContainer = this.illustrationContainer = element.querySelector (".sealing-areas-illustration");
	
	if (true || Environment.FAST_PASS) {
		/*
		window.setTimeout (_ => {
			this.highlightItem (items [0]);
			
		}, 250);
		*/
		
	}
	
};

SealingAreas.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	this.didHover = false;
	this.nextRotationTime = performance.now () + 4000;
	this.addRunLoopHandler ("processAutoRotation");
	
};

SealingAreas.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	this.removeRunLoopHandler ("processAutoRotation");
	
};

SealingAreas.prototype.processAutoRotation = function () {
	// if (this.isMouseOver && this.didHover)
	if (this.didHover)
		return;
	
	const now = performance.now ();
	if (now < this.nextRotationTime)
		return;
	
	this.nextRotationTime = now + 2500;
	
	let currentItemIndex = -1;
	
	const items = this.items;
	for (let i = items.length; i--;) {
		const item = items [i];
		if (item.classList.contains ("active")) {
			currentItemIndex = i;
			break;
			
		}
		
	};
	
	this.highlightItem (items [(currentItemIndex + 1) % items.length]);
	
};

SealingAreas.prototype.mouseOver = function (event) {
	this.isMouseOver = true;
	
};

SealingAreas.prototype.mouseOut = function (event) {
	this.isMouseOver = false;
	this.nextRotationTime = performance.now () + 4000;
	
};

SealingAreas.prototype.mouseOverItem = function (event) {
	this.didHover = true;
	
	const item = event.currentTarget;
	this.highlightItem (item);
	
};

SealingAreas.prototype.clickItem = function (event) {
	this.didHover = true;
	
	const item = event.currentTarget;
	this.highlightItem (item);
	
	const body = document.scrollingElement || document.body;
	const bodyBounds = body.getBoundingClientRect ();
	const illustrationBounds = this.illustrationContainer.getBoundingClientRect ();
	const viewportSize = this.getStage ().size;
	
	let targetOffset = Math.max (
		body.scrollTop,
		Math.min (
			illustrationBounds.top - bodyBounds.top,
			illustrationBounds.top + illustrationBounds.height * .975 - bodyBounds.top - viewportSize [1]
			
		)
		
	);
	Site.sharedInstance.scrollToOffset (targetOffset);
	
};

SealingAreas.prototype.highlightItem = function (item) {
	this.didHover = true;
	
	const id = item.dataset.stateName;
	
	const illustrationContainer = this.illustrationContainer;
	const illustrations = illustrationContainer.querySelectorAll ("img[data-state-name]");
	
	illustrations.forEach ((illustration, index) => {
		const isHighlighted = illustration.dataset.stateName == id;
		
		illustration.style.display = "";
		illustration.style.opacity = isHighlighted ? 1 : 0;
		
		this.items [index].classList.toggle ("active", isHighlighted);
		
	});
	
};
