import * as Environment from "../base/Environment.js";

import {LoaderSystem, DataLoaderJob} from "../base/Loader.js";

import {SiteSection} from "./SiteSection.js";

//
// ContactFinder extends SiteSection
//

export const ContactFinder = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.ContactFinder = ContactFinder;

ContactFinder.prototype = Object.create (SiteSection.prototype);

ContactFinder.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const brand = this.brand = element.getAttribute ("data-brand");
	
	const dynamicRow = this.dynamicRow = element.querySelector (".row:nth-child(2)");
	
	const loader = LoaderSystem.getSharedInstance ();
	const job = loader.jobForPathOfClass (
		element.getAttribute ("data-src") || "/_Resources/Static/Packages/Wysiwyg.Sievert.Website/Data/contact-finder.csv",
		DataLoaderJob
		
	);
	
	job.addListener ("complete", this.completeLoading, this);
	loader.enqueueJob (job);
	
};

ContactFinder.prototype.completeLoading = function (job) {
	const data = job.data;
	
	const entriesMap = this.entriesMap = new Object ();
	
	function insertEntry (entry, key) {
		function insertPartial (key) {
			let entries = entriesMap [key];
			if (entries) {
				entries.push (entry);
				
			} else {
				entriesMap [key] = [entry];
				
			}
			
		}
		
		key = key.toLowerCase ();
		for (let i = key.length; i--;)
			insertPartial (key.substr (0, i + 1));
		
	}
	
	const brand = this.brand;
	
	const lines = data.split ("\n");
	for (let i = 1; i < lines.length; i++) {
		const line = lines [i];
		if (!line)
			continue;
		
		const fields = line.split (";");
		
		let zipCode = fields [0];
		while (zipCode.length < 5)
			zipCode = "0" + zipCode;
		
		let contactEntry;
		
		switch (brand) {
			case "hahne":
				contactEntry = {
					zipCode: zipCode,
					placeName: fields [1].replace (/-[0-9]+$/, ""),
					contactId: fields [2]
					
				};
				break;
				
			default:
				/*
				let locality = fields [2];
				const spaceIndex = locality.indexOf (" ");
				if (spaceIndex >= 0)
					locality = locality.substr (spaceIndex + 1);
				
				insertEntry (contactEntry, locality);
				*/
				
				contactEntry = {
					zipCode: zipCode,
					placeName: fields [1],
					// locality: locality,
					region: fields [3],
					contactId: (fields [7].trim () || "andres.gomez@sievert.de") + "-" + fields [3]
					
				};
			
		}
		
		contactEntry.contactId = contactEntry.contactId.trim ().replace (/\s/g, "-").toLowerCase ();
		
		insertEntry (contactEntry, zipCode + " " + contactEntry.placeName);
		insertEntry (contactEntry, contactEntry.placeName);
		
	}
	
	const element = this.element;
	
	const form = this.form = element.querySelector ("form");
	form.addEventListener ("submit", this.submitForm.bind (this));
	
	const inputField = this.inputField = element.querySelector ("input#location");
	const typeAheadField = this.typeAheadField = element.querySelector (".form-type-ahead-text");
	
	inputField.addEventListener ("input", this.changeInput.bind (this));
	
	const missingMessage = this.missingMessage = element.querySelector (".contact-missing");
	this.missingMessageText = missingMessage.firstElementChild.textContent;
	
};

ContactFinder.prototype.changeInput = function (event) {
	const inputField = this.inputField;
	let value = inputField.value.replace(/^\s+/,"");
	if (inputField.value != value)
		inputField.value = value;
	
	const entriesMap = this.entriesMap;
	
	const entries = entriesMap [value.toLowerCase ()];
	const entry = this.currentEntry = entries && entries [0];
	
	const typeAheadField = this.typeAheadField;
	
	if (entry) {
		function lengthOfMatch (value, property) {
			if (!property)
				return;
			
			value = value.toLowerCase ();
			property = property.toLowerCase ();
			
			if (value.length > property.length) {
				const swap = property;
				property = value;
				value = swap;
				
			}
			
			const length = Math.min (property.length, value.length);
			for (let i = 0; i < length; i++) {
				if (property.indexOf (value) != 0)
					return i;
				
			}
			return length;
			
		}
		
		let matchLength;
		
		function updateTypeAheadField (property) {
			typeAheadField.entryText = property;
			typeAheadField.textContent =
				value.substr (0, matchLength) + property.substr (matchLength);
			
		};
		
		if (entry.locality) {
			matchLength = lengthOfMatch (value, entry.locality);
			if (matchLength) {
				updateTypeAheadField (entry.locality);
				return;
				
			}
			
		}
		
		matchLength = lengthOfMatch (value, entry.zipCode);
		if (matchLength) {
			updateTypeAheadField (entry.zipCode + " " + entry.placeName);
			return;
			
		}
		
		matchLength = lengthOfMatch (value, entry.placeName.toLowerCase ());
		if (matchLength) {
			updateTypeAheadField (entry.placeName);
			return;
			
		}
		
	}
	
	typeAheadField.entryText = "";
	typeAheadField.textContent = "";
	
};

ContactFinder.prototype.submitForm = function (event) {
	event.preventDefault ();
	
	const inputField = this.inputField;
	const typeAheadField = this.typeAheadField;
	
	const missingMessage = this.missingMessage;
	
	const entry = this.currentEntry;
	const entryKey = entry && entry.contactId;
	
	const dynamicRow = this.dynamicRow;
	dynamicRow.classList.remove ("hidden");
	
	const contactPersons = dynamicRow.querySelectorAll (".contact-person");
	
	let previousHeading;
	
	for (let i = contactPersons.length; i--;) {
		const contactPerson = contactPersons [i];
		const isContactPerson =
			contactPerson.id == entryKey ||
			contactPerson.id == "contact-person-" + entryKey;
		
		contactPerson.style.display = isContactPerson ? "" : "none";
		
		if (isContactPerson) {
			const metaHead = contactPerson.querySelector (".text-2xs");
			if (metaHead)
				metaHead.textContent = "Region " + entry.region;
			
			previousHeading = contactPerson;
			while (previousHeading && previousHeading.classList.contains ("contact-person")) {
				previousHeading = previousHeading.previousElementSibling;
				
			}
			
		}
		
	}
	
	function setHeadingsDisplayStyle (displayStyle) {
		const headings = dynamicRow.querySelectorAll (".contact-header");
		headings.forEach (heading => {heading.style.display = displayStyle});
		
	}
	
	if (typeAheadField.entryText) {
		inputField.value = typeAheadField.entryText;
		typeAheadField.textContent = "";
		
		missingMessage.style.display = "none";
		setHeadingsDisplayStyle ("none");
		
		if (previousHeading)
			previousHeading.style.display = "";
		
	} else {
		const searchText = inputField.value.trim ();
		
		if (searchText) {
			missingMessage.firstElementChild.textContent = this.missingMessageText.replace ("{search-term}", searchText);
			missingMessage.firstElementChild.style.display = "";
			setHeadingsDisplayStyle ("");
			
		} else {
			missingMessage.firstElementChild.style.display = "none";
			
		}
		
		missingMessage.style.display = "";
		setHeadingsDisplayStyle ("");
		
		switch (this.brand) {
			case "hahne":
				previousHeading = dynamicRow.querySelector (".contact-header");
				if (previousHeading)
					previousHeading.style.display = "none";
				
				break;
				
			default:
				contactPersons.forEach (contactPerson => {contactPerson.style.display = ""});
			
		}
		
	}
	
};
