import * as Environment from "../base/Environment.js";
import {docCookies} from "../libs/cookies.js";

import {RenderContext} from "../base/Display.js";
import {Stage, AnimatableSprite} from "../base/Display2D.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";
export {SiteSection};
import {GallerySection} from "./GallerySection.js";
import {XLTeaserSliderSection} from "./XLTeaserSliderSection.js";
import {TeaserGallerySection} from "./TeaserGallerySection.js";
import {Accordion} from "./Accordion.js";
import {ScrollableTable} from "./ScrollableTable.js";
import {VideoOverlay} from "./VideoOverlay.js";
import {ImageSequencer} from "./ImageSequencer.js";
import {IntroAnimation} from "./SiteAnimations.js";
import {ContactFinder} from "./ContactFinder.js";
import {ApplicationFinder} from "./ApplicationFinder.js";
import {DetailDrawings} from "./DetailDrawings.js";
import {ProductOverviewSlider} from "./ProductOverviewSlider.js";
import {StepByStep} from "./StepByStep.js";
import {ColourSelector} from "./ColourSelector.js";
import {SealingAreas} from "./SealingAreas.js";

import * as Tubag100YearsSections from "./Tubag100YearsSections.js";


//
// Site extends Stage
//

export const Site = function (context) {
	Stage.apply (this, arguments);
	
	if (Site.sharedInstance)
		throw new Error ("Cannot instantiate. Site is singleton.");
	
	Site.sharedInstance = this;
	
	var element = this.element;
	element.classList.remove ("sprite");
	
	this.createGridRuler ();
	
	var sections = this.sections = new Array ();
	
	this.setUpContent ();
	
	var lazyLoader = this.lazyLoader = new LazyLoader (element);
	
	context.resizeDispatcher.addListener ("scroll", this.scroll, this);
	
	this.addListener ("resize", this.resize, this);
	this.resize ();
	
	if (Environment.FAST_PASS) {
		/*
		window.setTimeout (function () {
			
		}.bind (this), 250);
		*/
		
	}
	
	if (Environment.IS_IN_NEOS_EDITOR)
		this.addMutationObserver ();
	
	window.setTimeout (
		this.resize.bind (this),
		50
		
	);
	
	if (document.documentElement.getAttribute ("lang") == "de")
		window.setTimeout (
			this.parseHyphens.bind (this),
			150
			
		);
	
};

window.Site = Site;
Site.prototype = Object.create (Stage.prototype);

Site.prototype.createGridRuler = function () {
	var gridRuler = this.gridRuler = document.createElement ("div");
	gridRuler.classList.add ("grid-ruler");
	
	var gridContainer = document.createElement ("div");
	gridContainer.classList.add ("container");
	
	var gridRow = document.createElement ("div");
	gridRow.classList.add ("row");
	
	var gridColumns = this.gridColumns = new Array ();
	
	for (var i = 12; i--;) {
		var gridColumn = document.createElement ("div");
		gridColumn.classList.add ("column");
		gridColumn.classList.add ("w-1/12");
		gridColumn.classList.add ("my-0");
		
		gridColumn.appendChild (document.createElement ("div"));
		
		gridColumns.push (gridColumn);
		gridRow.appendChild (gridColumn);
		
	}
	
	gridContainer.appendChild (gridRow);
	gridRuler.appendChild (gridContainer);
	
	document.body.appendChild (gridRuler);
	
};

Site.prototype.parseHyphens = function () {
	let hyphenElements = this.element.querySelectorAll ("main .product-name-hyphenated");
	hyphenElements = this.hyphenElements = Array.prototype.slice.call (hyphenElements);
	
	if (document.body.className.indexOf ("product-detail-page") >= 0) {
		const headline = this.element.querySelector ("h1");
		if (headline) {
			headline.classList.add ("product-name-hyphenated");
			hyphenElements.push (headline);
			
		}
		
	}
	
	if (hyphenElements.length) {
		window.setTimeout (
			this.processHyphens.bind (this),
			50
			
		);
		
	}
	
};

Site.prototype.processHyphens = function () {
	const hyphenElements = this.hyphenElements;
	
	const replacements = [
		["Pflaster", "Pflaster⤚"],
		["fugen", "⤚fugen⤚"],
		["mörtel", "⤚mörtel⤚"],
		["Haft", "Haft⤚"],
		["schlämme", "⤚schlämme"],
		["schiene", "⤚schiene"],
		["Rollen", "Rollen⤚"],
		["bettungs", "⤚bettungs⤚"],
		["Sprüh", "Sprüh⤚"],
		["Wasser", "Wasser⤚"],
		["durch", "⤚durch⤚"],
		["dicht", "⤚dicht"],
		["Trass", "Trass⤚"],
		
		["Kalk", "Kalk⤚"],
		["kalk", "⤚kalk⤚"],
		["Wärme", "Wärme⤚"],
		["gewebe", "⤚gewebe⤚"],
		["harz", "⤚harz"],
		["finish", "⤚finish"],
		["putz", "⤚putz"],
		["träger", "⤚träger⤚"],
		["leicht", "⤚leicht"],
		["assaden", "assaden⤚"],
		["farbe", "⤚farbe"],
		
		["Sockel", "Sockel⤚"],
		["kante", "⤚kante"],
		["profil", "⤚profil"],
		["spachtel", "⤚spachtel⤚"],
		["schaum", "⤚schaum⤚"],
		["kleber", "⤚kleber"],
		["platte", "⤚platte"],
		["element", "⤚element"],
		["befestiger", "⤚befestiger"],
		["dämm", "⤚dämm"],
		["konsole", "⤚konsole"],
		["begrünung", "⤚begrünung"],
		["anschluss", "⤚anschluss⤚"],
		["abdeckkappen", "abdeck⤚kappen"],
		["beschleuniger", "⤚beschleuniger"],
		
		["Fugen", "Fugen⤚"],
		["abdeck", "⤚abdeck⤚"],
		["beschichtung", "⤚beschichtung"],
		["stärkungs", "stärkungs⤚"],
		
		["ß", "ß⤚"],
		["/", "/\u200b"],
		
	];
	
	let i = 10;
	while (i-- && hyphenElements.length) {
		const hyphenElement = hyphenElements.shift ();
		const childNodes = hyphenElement.childNodes;
		
		const maxSyllableLength = 14;
		let longestRun = 0;
		
		for (let i = childNodes.length; i--;) {
			const childNode = childNodes [i];
			/*
			if (childNode.nodeType != 3)
				continue;
			*/
			
			let text = childNode.textContent.replace (/\t/g, "");
			
			for (let i = replacements.length; i--;) {
				const replacement = replacements [i];
				text = text.replaceAll (replacement [0], replacement [1]);
				
			}
			
			while (text.indexOf ("⤚⤚") >= 0)
				text = text.replaceAll ("⤚⤚", "⤚");
			while (text.indexOf ("⤚-") >= 0)
				text = text.replaceAll ("⤚-", "-");
			while (text.indexOf ("-⤚") >= 0)
				text = text.replaceAll ("-⤚", "-");
			
			const splitText = text.split (/[⤚ -]/);
			
			for (let i = splitText.length; longestRun < maxSyllableLength && i--;)
				longestRun = Math.max (longestRun, splitText [i].length)
			
			childNode.textContent = text.replaceAll ("⤚", "\u00ad");
			
		}
		
		if (longestRun < maxSyllableLength)
			hyphenElement.classList.remove ("product-name-hyphenated");
		else
			hyphenElement.style.hyphens = hyphenElement.style.webkitHyphens = "auto";
		
		hyphenElement.style.overflowWrap = "anywhere";
		
	}
	
	if (hyphenElements.length) {
		window.setTimeout (
			this.processHyphens.bind (this),
			50
			
		);
		
	} else {
		this.resize ();
		
	}
	
};

Site.prototype.setUpContent = function () {
	const element = this.element;
	
	this.setUpExpandedImageContainers (element);
	if (!Environment.IS_IN_NEOS_EDITOR)
		this.setUpResponsiveTables (element);
	this.setUpSectionControllers (element);
	this.setUpCookieLinks ();
};

Site.prototype.setUpCookieLinks = function () {
	const cookieLinks = this.element.querySelectorAll ("[href='#change-cookies']");
	for (let i = cookieLinks.length; i--;) {
		const cookieLink = cookieLinks [i];
		cookieLink.addEventListener ("click", function (event) {
			event.preventDefault ();
			document.querySelector ("#changeCookies").click ();
			
		});
		
	}
	
};

Site.prototype.setUpExpandedImageContainers = function (element) {
	var expandedImageContainers = this.expandedImageContainers = element.querySelectorAll ("[data-extend-image]");
	
	expandedImageContainers.forEach (expandedImageContainer => {
		const image = element.querySelector ("img");
		image.addEventListener ("load", event => {
			this.updateExpandedImageContainer (expandedImageContainer);
			
		});
		
	});
	
};

Site.prototype.setUpResponsiveTables = function (element) {
	element.querySelectorAll ("[data-use-responsive-tables=yes]").forEach (text => {
		text.querySelectorAll ("table").forEach (table => {
			table.classList.add ("generic-table");
			
			const controller = document.createElement ("div");
			controller.className = "mt-15 mb-60";
			controller.dataset.className = "ScrollableTable";
			
			const tableContainer = document.createElement ("div");
			tableContainer.className = "table-container";
			
			const progressBar = document.createElement ("div");
			progressBar.className = "scrollable-table-progress-bar";
			
			table.parentNode.parentNode.insertBefore (controller, table.parentNode);
			table.parentNode.remove ();
			
			controller.appendChild (tableContainer);
			tableContainer.appendChild (table);
			
			controller.appendChild (progressBar);
			
		});
		
	});
	
};

Site.prototype.setUpSectionControllers = function (element) {
	var sectionElements = element.querySelectorAll ("[data-class-name]");
	if (!sectionElements.length)
		sectionElements = [element];
	
	var sections = this.sections;
	for (var i = sections.length; i--;) {
		var section = sections [i];
		section.isInUse = false;
		
	}
	
	for (var i = 0; i < sectionElements.length; i++) {
		var sectionElement = sectionElements [i];
		var section = sectionElement.section;
		
		if (!section) {
			sectionElement.setAttribute ("data-processed", "yes");
			
			var sectionClassName = sectionElement.getAttribute ("data-class-name");
			
			section = sectionElement.section = this.attachSprite (window [sectionClassName] || SiteSection);
			section.takeElement (sectionElement);
			
			sections.push (section);
			
		}
		
		section.isInUse = true;
		
	}
	
	for (var i = sections.length; i--;) {
		var section = sections [i];
		if (!section.isInUse)
			sections.splice (i, 1);
		
	}
	
};

Site.prototype.addMutationObserver = function () {
	var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
	mutationObserver.observe (this.element, {
		childList: true,
		subtree: true
		
	});
	
};

Site.prototype.mutateElement = function (mutations) {
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

Site.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	// trace ("---- handle mutation ----");
	
	this.isMutating = true;
	
	this.setUpContent ();
	
	var element = this.element;
	
	this.setUpSectionControllers (element);
	
	this.resize (this);
	
	this.dispatchEvent ("mutate");
	
	this.isMutating = false;
	
};

Site.prototype.resizeContext = function (context) {
	this.setSize (
		true || Environment.IS_TOUCH_DEVICE ?
			RenderContext.getViewportSize () :
			this.getRulerSize ()
		
	);
	
};

Site.prototype.resize = function (sender) {
	var gridColumns = this.gridColumns;
	
	const gutterWidth = this.gutterWidth =
		gridColumns [1].firstElementChild.getBoundingClientRect ().left -
		gridColumns [0].firstElementChild.getBoundingClientRect ().right;
	
	const columnWidth = this.columnWidth =
		gridColumns [0].firstElementChild.getBoundingClientRect ().width;
	
	this.updateExpandedImageContainers ();
	
	var viewportSize = this.size;
	var sections = this.sections;
	
	for (var i = sections.length; i--;) {
		var section = sections [i];
		section.setViewSize (viewportSize);
		
	}
	
	if (sender)
		this.renderInContext ();

	this.scroll ();
	
};

Site.prototype.updateExpandedImageContainers = function () {
	var expandedImageContainers = this.expandedImageContainers;
	
	if (expandedImageContainers && expandedImageContainers.length)
		expandedImageContainers.forEach (this.updateExpandedImageContainer, this);
	
};

Site.prototype.updateExpandedImageContainer = function (expandedImageContainer) {
	var viewSize = this.size;
	var parentBounds = this.element.getBoundingClientRect ();
	
	const isMobile = viewSize [0] < 560;
	const isTablet = !isMobile && viewSize [0] < 1024;
	const isDesktop = !isMobile && !isTablet;
	
	const maxWidth = 930;
	
	const extensionDirection = expandedImageContainer.getAttribute ("data-extend-image");
	
	const expandToLeft =
		isMobile && !isTablet ||
		(isDesktop && extensionDirection == "left");
	const expandToRight =
		isMobile && !isTablet ||
		(isDesktop && extensionDirection == "right");
	
	const figure = expandedImageContainer.querySelector ("figure");
	figure.style.position = "relative";
	
	const picture = figure.firstElementChild;
	if (!picture)
		return;
	const img = picture.lastElementChild;
	
	figure.style.paddingBottom = "";
	img.style.width = "";
	img.style.height = "";
	img.style.maxWidth = "";
	img.style.position = "";
	
	img.parentNode.style.width = "";
	img.parentNode.style.height = "";
	
	const originalBounds = figure.getBoundingClientRect ();
	
	figure.parentNode.style.setProperty ("--offset-left",
		-(Math.min (originalBounds.left, 250) - parentBounds.left - 50) + "px");
	figure.parentNode.style.setProperty ("--offset-right",
		(Math.max (originalBounds.right - parentBounds.right, -250) + 50) + "px");
	
	const originalSize = [
		originalBounds.width,
		originalBounds.height
		
	];
	
	if (!originalSize [1] && !expandedImageContainer.timeout) {
		expandedImageContainer.timeout = window.setTimeout (_ => {
			expandedImageContainer.timeout = undefined;
			this.updateExpandedImageContainer (expandedImageContainer);
			
		}, 50);
		
		return;
		
	}
	
	const ratio = originalSize [1] / originalSize [0];
	let layoutWidth = Math.min (maxWidth,
		expandToLeft && expandToRight ?
			viewSize [0]:
		expandToLeft ?
			originalBounds.right - parentBounds.left :
		expandToRight ?
			parentBounds.width - originalBounds.left - parentBounds.left :
			parentBounds.width - (originalBounds.left - parentBounds.left) * 2
		
	);
	
	const layoutHeight = Math.round (layoutWidth * ratio);
	layoutWidth = Math.round (layoutWidth);
	
	img.style.width = layoutWidth + "px";
	img.style.height = layoutHeight + "px";
	img.style.maxWidth = "none";
	img.style.position = "absolute";
	
	if (expandToLeft && expandToRight) {
		img.style.left = "0";
		img.style.right = "";
		
		img.parentNode.style.transform = "translateX(-20px)";
		
	} else if (expandToLeft) {
		img.style.left = "";
		img.style.right = "0";
		
		if (img.parentNode.style.position) {
			let offsetLeft = originalBounds.left - parentBounds.left;
			
			const isLargerImage = expandedImageContainer.parentNode.classList.contains ("lg:w-7/12");
			if (viewSize [0] >= 1440)
				offsetLeft = Math.min (isLargerImage ? 185 : 300, offsetLeft);
			
			img.parentNode.style.transform = "translateX(" + -offsetLeft + "px)";
			
		} else {
			img.parentNode.style.transform = "";
			
		}
		
	} else {
		img.style.left = "0";
		img.style.right = "";
		
		if (img.parentNode.style.transform)
			img.parentNode.style.transform = "";
		
	}
	
	img.parentNode.style.width = img.style.width;
	img.parentNode.style.height = img.style.height;
	
	expandedImageContainer.style.height = layoutHeight + "px";
	
};

Site.prototype.scroll = function (sender) {
	var size = this.size;
	var sections = this.sections;
	
	for (var i = sections.length; i--;) {
		var section = sections [i];
		var sectionElement = section.element;
		
		var pageBounds = section.pageBounds = sectionElement.getBoundingClientRect ();
		
		if (// pageBounds.height &&
			pageBounds.top < size [1] * 1.25 &&
			pageBounds.bottom > -size [1] * .5) {
			if (!section.didStartLoading) {
				section.startLoading ();
				
			}
			
		}
		
		if (// pageBounds.height &&
			pageBounds.top < size [1] + (section.viewOffsetTop || 0) &&
			pageBounds.bottom >= 0) {
			if (!section.isAwake)
				section.awake ();
			
			if (!section.forceScroll)
				section.scroll (!sender);
			
		} else {
			if (section.isAwake)
				section.sleep ();
			
		}
		
		if (section.forceScroll)
			section.scroll (!sender);
		
	}
	
	var lazyLoader = this.lazyLoader;
	lazyLoader.update ();
	
};

Site.prototype.scrollToOffset = function (targetOffset) {
	var scrollOffset = RenderContext.getScrollOffset () [1];
	var scrollStart = this.scrollStart = scrollOffset;
	
	if (scrollStart != targetOffset || this.scrollEnd != targetOffset) {
		this.scrollEnd = targetOffset;
		this.isScrolling = true;
		
		var delta = Math.abs (scrollStart - targetOffset);
		this.startAnimation ("Scroll", {direction: 1, rate: Math.max (
			.01, .1 / 4 / Math.max (
				1, delta / 640
				
			)
			
		) * 1.5, phase: 0});
		
	}
	
};

Site.prototype.animateScroll = function () {
	var state = this.updatedState ("Scroll");
	var t = 1 - state.phase;
	t = 1 - t * t * t;
	t = .5 - Math.cos (t * Math.PI) / 2;
	var t_ = 1 - t;
	
	if (Math.abs (this.scrollStart * t_ + this.scrollEnd * t - this.scrollEnd) < 1)
		this.isScrolling = false;
	
	var scrollTop = Math.round (this.scrollStart * t_ + this.scrollEnd * t);
	window.scroll (0, scrollTop);
	
};

Site.prototype.openVideoLayerFromSourceElement = function (sourceElement) {
	let allowsTracking = false;
	
	try {
		const cookies = JSON.parse (docCookies.getItem ("cookieConsent"));
		
		// if (cookies.technical.cookies.indexOf ("cookieConsentAccepted") >= 0)
		if (cookies.statistics.accepted)
			allowsTracking = true;
		
	} catch (exception) {}
	
	const vimeoVideoId = sourceElement.getAttribute ("data-vimeo-video-id");
	const openExternalSite = vimeoVideoId && !allowsTracking;
	
	if (openExternalSite) {
		if (vimeoVideoId) {
			window.open ("https://vimeo.com/" + vimeoVideoId, "_blank");
			
		} else {
			var youTubeVideoId =
				sourceElement.getAttribute ("data-you-tube-video-id") ||
				sourceElement.getAttribute ("data-video-id");
			
			window.open ("https://www.youtube.com/watch?v=" + youTubeVideoId);
			
		}
		
	} else {
		var videoController = this.videoController;
		if (!videoController) {
			videoController = this.videoController = this.attachSprite (OverlayVideoPlayer);
			document.body.appendChild (videoController.element);
			
		}
		
		videoController.startVideoWithSourceElement (sourceElement);
		
		document.body.classList.add ("scroll-locked");
		
	}
	
};

Site.prototype.closeVideoLayer = function (navigation) {
	var videoController = this.videoController;
	videoController.close ();
	
	document.body.classList.remove ("scroll-locked");
	
};
